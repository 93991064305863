import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {MyPointsContext} from '../src/Context';
import {requestGet} from '../../../../../utils/httpReq';
import {isUserAdmin} from '../../../../../utils';
import RenderTabTable from './RenderTabTable';
import AddPointForm from './AddPointForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    > {value === index && ( <Box p={3}> {children} </Box> )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VerticalTabs({uid}) {
    const [myPointsData, setMyPointsData] = React.useContext(MyPointsContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles();
    const yearList = myPointsData.displayPoints.years || [];
  const userId = isUserAdmin() ? uid : JSON.parse(sessionStorage.getItem('userSession')).id;
    const handleChange = (event, newValue) => {
      setIsLoading(true);
      requestGet(
        `point/listing_year/${userId}/${yearList[newValue]}`,
        (resp)=> setMyPointsData({
          ...myPointsData,
          displayPoints:resp,
          slctdYearIdx: newValue,
          slctdYear: yearList[newValue],
        }),
        (loading)=> setIsLoading(loading),
      );
    };
    return (
      <>
        <h1 style={{fontWeight:400, fontSize:'1.4em'}}>My Point Listing</h1>
        {isUserAdmin() ? '' : <AddPointForm/>}
          <div className={classes.root}>
              <Tabs
                value={myPointsData.slctdYearIdx}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              > {yearList.map((item,indx)=><Tab className='tabItem' key={item+indx} label={item} {...a11yProps(indx)} />)}
              </Tabs>
              {yearList.map((item,indx)=>(
                <TabPanel key={indx} style={{width:'100%'}} value={myPointsData.slctdYearIdx} index={indx}>
                  <RenderTabTable
                    dataVal={myPointsData.displayPoints.data}
                    isLoading={isLoading}
                  />
                </TabPanel>
              ))}
          </div>
      </>
    );
}
