import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Button from '@material-ui/core/Button'
import getCroppedImg from './Components/cropImage'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({imageVal, setCroppedImage, openImgCrop, closeImgCrop}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    closeImgCrop();
    setOpen(false);
  };
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageVal,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, rotation]);
  React.useEffect(()=>{
    setOpen(openImgCrop)
  },[openImgCrop])
  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth='lg'
    >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Crop Profile Image
        </DialogTitle>
        <DialogContent dividers>
            
        <div style={{
            position:'relative',
            minHeight: '70vh',
            minWidth: '70vw'
        }}>
        <div>
            <Cropper
            image={imageVal}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            />
        </div>
        {/* <div >
            <Button
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
            > Crop Image
            </Button>
        </div> */}
        </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{
              showCroppedImage();
              handleClose();
          }} color="primary">
            Select Image
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
