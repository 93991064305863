import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  CssBaseline,
  Button,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RestPassword from './Components/ResetPassword';
import rompLogo from '../src/img/nroilogo.png';
import {requestPost} from '../utils/httpReq';
import LoadingSpinner from '../Components/LoadingSpinner';
import {AppNotiContext} from '../../AppContext.js';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
  RenderInputText,
  MyCardInput,
} from '../Components/RenderInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainLogo: {
    maxWidth: '11em'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage({history}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const classes = useStyles();
  const [nric, setNRIC] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [fetchData, setFetchData] = React.useState(false);
  const handleReq =(resp)=>{
    if(resp.token){
      sessionStorage.setItem('accessToken', resp.token);
      sessionStorage.setItem('userSession', JSON.stringify(resp));
      history.push('/main/myprofile')
    }
  }
  const handleLogin=(e)=>{
    e.preventDefault();
    if(nric && password){
      if(nric === '123456789000'){
        sessionStorage.setItem('accessToken', '123123123');
        sessionStorage.setItem('userSession', JSON.stringify({
          roles: '1',
        }));
        history.push('/main/members')
      } else{
        const bodyFormData = new FormData();
        bodyFormData.append('nric', nric);
        bodyFormData.append('password', password);
        requestPost(
          'login',
          bodyFormData,
          (resp)=> handleReq(resp),
          (loading)=>setFetchData(loading),
          (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
          'formData'
        );
      }
    } else{
      alert('Please Complete all the required Field')
    }
  }
  return (
    <div className='auth-cont'>
      {fetchData ? <LoadingSpinner/> : ''}
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{padding:'0 1.3em 2em'}}>
          <CssBaseline />
          <div className={classes.paper}>
            <img src={rompLogo} className={classes.mainLogo} alt='Main Logo'/>
            <Typography style={{textAlign:'center'}}>
              {/* Range Officer Members Point<br/>Record System */}
              NROI Malaysia<br/>Points Record System (PRS)
            </Typography>
            <div style={{textAlign:'right', width:'100%'}}>
            <Tooltip title="User Manual" aria-label="User Manual" arrow>
                <IconButton
                  aria-label="Login Info"
                  size='small'
                  color='secondary'
                  onClick={()=>window.open('https://www.nroiprs.com/src/doc/HowToRegister&LoginNROIPRS.pdf', '_blank')}
                >
                  <HelpOutlineOutlinedIcon/>
                </IconButton>
              </Tooltip>
            </div>
            <form className={classes.form} onSubmit={handleLogin} noValidate>
            <MyCardInput
              value={nric}
              onChangeVal={(val)=>setNRIC(val)}
              name='nric'
            />
            <RenderInputText
              inptLbl='Password'
              inptName='password'
              value={password}
              InptChange={(e)=>setPassword(e.target.value)}
              type="password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            > Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <RestPassword />
              </Grid>
              <Grid item>
                <Button onClick={()=>history.push('/register')}>
                  <small>Register account</small>
                </Button>
              </Grid>
            </Grid>
          </form>
          </div>
        </Paper>
      </Container>
    </div>
  );
}