import React from 'react';
import {
  Paper,
} from '@material-ui/core';
import VerticalTabs from './Components/VerticalTabs';
import InfoBar from './Components/InfoBar';
import {MyPointsProvider,MyPointsContext} from './src/Context';
import LoadingSpinner from '../../../../Components/LoadingSpinner';
import {requestGet} from '../../../../utils/httpReq';
import {isUserAdmin} from '../../../../utils';
import './src/csss/points.scss';

function RenderItem({uid}){
  const [myPointsData, setMyPointsData] = React.useContext(MyPointsContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const userId = isUserAdmin() ? uid : JSON.parse(sessionStorage.getItem('userSession')).id;
  React.useEffect(()=>{
    requestGet(
      `point/listing/${userId}`,
      (resp)=> setMyPointsData({...myPointsData, displayPoints:resp}),
      (loading)=> setIsLoading(loading),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return(
    <div className='my-points-cont'>
      {isLoading ? <LoadingSpinner/> : ''}
      <InfoBar/>
      <VerticalTabs uid={userId}/>
    </div>
  );
}

export default function MyPoints({uid}) {
  return (
      <MyPointsProvider>
          <Paper elevation={3} style={{padding:'1em 1em 2em'}}>
            <RenderItem uid={uid}/>
          </Paper>
      </MyPointsProvider>
  );
}
