import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Header from './Components/Header';
import ShooterList from './Components/Container/ShooterList';
import Profile from './Components/Container/Profile';
// import ViewProfile from './Components/Container/ShooterList/Components/AdminView';
import Verifications from './Components/Container/Verifications';
import Matches from './Components/Container/Matches';
import Points from './Components/Container/Points';
import { MainContextProvider, MainContext } from './src/Context'
import LoadingSpinner from '../Components/LoadingSpinner';
import {AppNotiContext} from '../../AppContext.js';
import {isUserAdmin} from '../utils';

function RenderMainApp(){
  const [mainContextData] = React.useContext(MainContext);
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  let expiredCount = JSON.parse(sessionStorage.getItem('userSession')).month_expiry;
  React.useEffect(()=>{
    if(expiredCount < 12 && expiredCount > 0) setAppNoti({
      ...appNoti,
      openSuccess:true,
      successTitle: <>Friendly Reminder !</>,
      sucessMsg: <>
        Your Membership will be expires in 
        <b style={{color:'red'}}> {expiredCount}</b> Months, <br/>
        Activate Your Account by Creating Match Points.
      </>,
    });
  },[]);
  const isUserAdmin = JSON.parse(sessionStorage.getItem('userSession')).roles === '1';
  return(
    <section className='app-cont'>
      {mainContextData.loading ? <LoadingSpinner/> : ''}
        <Header childComp={
          <>
            <Router>
              <Switch>
                {/* <Route  path={['/', '/main','/:pageType?/:pageAtt?']} component={MainPage} /> */}
                {/* <Route exact path="/main" component={ShooterList} /> */}
                <Route exact path="/main" component={isUserAdmin ? ShooterList : Profile} />
                <Route exact path="/main/myprofile" component={Profile} />
                <Route exact path="/main/members" component={ShooterList} />
                {/* <Route exact path="/main/viewprofile/:id?" component={ViewProfile} /> */}
                <Route exact path="/main/mymatches" component={Matches} />
                <Route exact path="/main/verifications" component={Verifications} />
                <Route exact path="/main/mypoints" component={Points} />
              </Switch>
            </Router>
          </>
        }/>
    </section>
  );
}
function App() {
  return (
    <MainContextProvider>
      <RenderMainApp />
    </MainContextProvider>
  );
}

export default App;
