import React from 'react';
import { withRouter } from "react-router-dom";
import {
  Paper,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import {requestGet} from '../../../../utils/httpReq';
import {
  isUserAdmin,
  sortPoint
} from '../../../../utils/';
import TableData from '../../../../Components/TableData';
import AdminSlct from './Components/AdminSlct';

function StickyHeadTable({history}) {
  const [fetchData, setFetchData] = React.useState(true);
  const [rows, setUserListing] = React.useState([]);
  React.useEffect(()=>{
    requestGet(
      `user/listing`,
      (resp)=> setUserListing(resp),
      (loading)=>setFetchData(loading)
    )
  },[]);
  return (
    <>
      <Paper elevation={3} style={{padding:'1em 1em 2em'}}>
        <TableData
          title="NROI Member List"
          data={rows}
          isLoading={fetchData}
          columns={[
            {
                title: 'IROA/NROI ID',
                field: 'iroa_id'
            },
            {
                title: 'Name',
                field: 'name'
            },
            {
                title: 'Total Points',
                field: 'total_points',
                customSort: (a, b) => sortPoint(parseFloat(a.total_points),parseFloat(b.total_points))
            },
            {
                title: 'Last Match',
                field: 'last_match'
            },
            {
                title: 'Rank',
                field: 'rank_stat'
            },
            {
                title: 'Status',
                field: 'i_disabled',
                hidden: !isUserAdmin(),
                render: rowData => !parseInt(rowData.i_disabled) ?
                    <div className='tbl-active-icon'>
                      <CheckCircleOutlineIcon/>
                      <p><small>Active</small></p>
                    </div>
                    :
                    <div className='tbl-active-icon notActive'>
                      <BlockRoundedIcon/>
                      <p><small>Inactive</small></p>
                    </div>,
            },
            {
              title: 'Action',
              hidden: !isUserAdmin(),
              sorting: false,
              render: rowData =><AdminSlct uid={rowData.id}/>
            },
          ]
        }
          // onRowClick={(e, rowData)=>handleOnclick(rowData.id)}
          options={{ 
            exportButton: isUserAdmin(),
            pageSize: 10,
          }}
        />
      </Paper>
    </>
  );
}

export default  withRouter(StickyHeadTable);