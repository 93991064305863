
export const convertFormData = (objct) => {
  const formData = new FormData();
  Object.keys(objct).forEach(key => objct[key] && formData.append(key, objct[key]));
  return formData;
}

export const sortStringDate= (a,b)=>{
  a = a.split('/').reverse().join('');
  b = b.split('/').reverse().join('');
  return a > b ? 1 : a < b ? -1 : 0;
}

export const sortPoint = (a,b)=>  a > b ? 1 : a < b ? -1 : 0;

export const isUserAdmin = ()=> JSON.parse(sessionStorage.getItem('userSession')).roles === '1' || false;