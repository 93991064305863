import React from 'react';

export const MatchContext = React.createContext();
export const MatchContextProvider = props =>{
    const [appMatch, setMatch] = React.useState({
        matchList: null,
        createdMatchId: 2,
        userList: [
            {"value":"9","label":"Carleton"},
            {"value":"2","label":"Chloe"},
            {"value":"8","label":"Columbus"},
            {"value":"5","label":"Creola"},
            {"value":"3","label":"Emilio"},
            {"value":"4","label":"Euna"},
            {"value":"1","label":"Marco"},
            {"value":"7","label":"Nelson"},
            {"value":"10","label":"Sylvan"},
            {"value":"6","label":"Vicente"}
          ],
    });
    return(
        <MatchContext.Provider value={[appMatch, setMatch]}>
            {props.children}
        </MatchContext.Provider>
    );
};