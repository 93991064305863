import React from 'react';

export const AppNotiContext = React.createContext();
export const AppNotiContextProvider = props =>{
    const [appNoti, setAppNoti] = React.useState({
        openErr: false,
        errObj:{
            data:{
                message:''
            }
        },
        sucessMsg:'',
        openSuccess: false
    });
    return(
        <AppNotiContext.Provider value={[appNoti, setAppNoti]}>
            {props.children}
        </AppNotiContext.Provider>
    );
};