
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function PasswordForm({dialogStatus, closeDialog, pswdVal, submitForm}) {
  const [uPswd, setUpswd] = React.useState('');
  const [uConfirmPswd, setuConfirmPswd] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
      setOpen(false);
      closeDialog();
  };
  React.useEffect(()=>{
      if(dialogStatus !== open) setOpen(dialogStatus);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dialogStatus]);
  const submitpswd=(e)=>{
    e.preventDefault();
    submitForm();
  }
  return (
    <div>
      <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Set Account Password</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    label="Password"
                    type="password"
                    value={uPswd}
                    onChange={(e)=>setUpswd(e.target.value)}
                />
                <TextField
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        value={uConfirmPswd}
                        onChange={(e)=>{
                            setuConfirmPswd(e.target.value);
                            pswdVal(e.target.value);
                        }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={submitpswd}
                    type='submit'
                    color="primary"
                >Register
                </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}
