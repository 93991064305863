import React from 'react';
import RenderCreateMatchForm from './RenderCreateMatchForm';
import {requestPost} from '../../../../../utils/httpReq';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {MatchContext} from '../src/Context';
import {AppNotiContext} from '../../../../../../AppContext.js';
import {MainContext} from '../../../../src/Context';
import './src/css/creatematchdialog.scss'

export default function CreateMatchDialog({isEdit, editData, openDialog, closeDialog, matchId}) {
  const [open, setOpen] = React.useState(false);
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [appMatch, setMatch] = React.useContext(MatchContext);
  const [mainContextData, setMainContextData] = React.useContext(MainContext);
  const [createMatch, setCreateMatch] = React.useState(true);
  const [formObj, setFormObj] = React.useState([]);
  const convertFormData = (objct) => {
    const formData = new FormData();
    Object.keys(objct).forEach(key => formData.append(key, objct[key]));
    return formData;
  }
  const convertMultipleData=(multiData) => multiData.map((item)=>({label: item.name, value: item.id}));
  const handlePostData=(e)=>{
    e.preventDefault();
    if(isEdit){
      if(isEdit === 'editMember'){
        const newData = formObj.map((item)=>item.value);
        requestPost(
          `match/update/user/${matchId}`,
          convertFormData({members:JSON.stringify(newData)}),
          ()=> {
            setAppNoti({
              ...appNoti,
              openSuccess:true,
              sucessMsg: 'Match Updated Successfully!'
            });
            handleClose();
          },
          (loading)=> setMainContextData({
            ...mainContextData,
            loading: loading
          }),
          (err)=>setAppNoti({
            ...appNoti,
            openErr:true,
            errObj: err
          }),
        )
      }else{
        requestPost(
          `match/update/${matchId}`,
          convertFormData(formObj),
          (resp)=> {
            setMatch({
              ...appMatch,
              matchList: resp.data,
            });
            setAppNoti({
              ...appNoti,
              openSuccess:true,
              sucessMsg: 'Match Created Successfully!'
            });
            handleClose();
          },
          (loading)=> setMainContextData({
            ...mainContextData,
            loading: loading
          }),
          (err)=>setAppNoti({
            ...appNoti,
            openErr:true,
            errObj: err
          }),
        )
      }
    }else if(createMatch){
      requestPost(
        'match/store',
        convertFormData(formObj),
        (resp)=> {
          setMatch({
            ...appMatch,
            userList: convertMultipleData(resp.data),
            createdMatchId: resp.match_id
          });
          setCreateMatch(false);
        },
        (loading)=> setMainContextData({
          ...mainContextData,
          loading: loading
        }),
        (err)=>setAppNoti({
          ...appNoti,
          openErr:true,
          errObj: err
        }),
      )
    }else{
      const newData = formObj.map((item)=>item.value);
      requestPost(
        `match/store/user/${appMatch.createdMatchId}`,
        convertFormData({members:JSON.stringify(newData)}),
        (resp)=> {
          setMatch({
            ...appMatch,
            matchList: resp.data,
          });
          setCreateMatch(true);
          handleClose();
        },
        (loading)=> setMainContextData({
          ...mainContextData,
          loading: loading
        }),
        (err)=>setAppNoti({
          ...appNoti,
          openErr:true,
          errObj: err
        }),
      )
      setAppNoti({
        ...appNoti,
        openSuccess:true,
        sucessMsg: 'Match Created Successfully!'
      });
    }
  }
  const handleClose = () => { setOpen(false); closeDialog(); };
  React.useEffect(()=>{
    setOpen(openDialog)
  },[openDialog])
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            {isEdit && isEdit.includes('edit') ? 'Edit' : 'Create'} Match
          </DialogTitle>
        <DialogContent>
          {isEdit === 'editMember' ?
            <RenderCreateMatchForm
              formData={(data)=>setFormObj(data)}
              editData={editData}
            />
            :
            <RenderCreateMatchForm
              isCreateMatch={createMatch}
              formData={(data)=>setFormObj(data)}
              editData={editData}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePostData} color="primary">
            {isEdit && isEdit.includes('edit') ? 'Edit' : 'Create'} Match
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
