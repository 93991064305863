import React from 'react';
import {requestGet} from '../../../../../utils/httpReq';
import CreateMatchDialog from '../Components/CreateMatchDialog';
import {MatchContext} from '../src/Context';
import { MainContext } from '../../../../src/Context';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

export default function LongMenu({matchId}) {
    const [mainContextData, setMainContextData] = React.useContext(MainContext);
    const [appMatch, setMatch] = React.useContext(MatchContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [matchDialog, setMatchDialog] = React.useState(false);
    const [isEditMember, setIsEditMember] = React.useState(false);
    const [editData, setEditData] = React.useState([]);
    const convertMultipleData=(multiData) => multiData.map((item)=>({label: item.name, value: item.id}));
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (item) => {
      setIsEditMember(item);
      setAnchorEl(null);
      if(item === 'edit'){
        requestGet(
          `match/show/${matchId}`,
          (resp)=>setEditData(resp),
          (loading)=> setMainContextData({...mainContextData,loading: loading}),
        );
      } else if(item === 'editMember'){
        requestGet(
          `match/user/${matchId}`,
          (resp)=>{
            setEditData(convertMultipleData(resp.filter(x => x.selected === '1')));
            setMatch({
              ...appMatch,
              userList: convertMultipleData(resp),
            });
          },
          (loading)=> setMainContextData({...mainContextData,loading: loading}),
        );
      }
      setMatchDialog(true);
    };
    return (
      <>
        <CreateMatchDialog
          openDialog={matchDialog}
          closeDialog={()=>setMatchDialog(false)}
          editData={editData}
          isEdit={isEditMember}
          matchId={matchId}
        />
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        > <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          // onClose={()=>handleClose()}
          onClose={()=>setAnchorEl(null)}
        >
          <MenuItem style={{color:'#3f51b5'}} onClick={()=>handleClose('edit')}>
              <CreateOutlinedIcon/>&nbsp;Edit
          </MenuItem>
          <MenuItem style={{color:'#3f51b5'}} onClick={()=>handleClose('editMember')}>
              <PeopleOutlineIcon/>&nbsp;User Listing
          </MenuItem>
        </Menu>
      </>
    );
  }
  