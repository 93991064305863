import React from 'react';
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
import {RenderInputText} from '../../../../Components/RenderInput';
import {requestPost} from '../../../../utils/httpReq';
import {AppNotiContext} from '../../../../../AppContext.js';
 
function RenderPasswordInput({label, InptChange, inptName}){
    const [pswdTxt, setPswdTxt] = React.useState('');
    return (
      <>
        <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            type="password"
            label={label ? label : 'Password' }
            name={inptName || 'password'}
            value={pswdTxt}
            onChange={(e)=>{
              setPswdTxt(e.target.value);
              InptChange(e);
            }}
        />
          <PasswordStrengthBar
            password={pswdTxt}
            minLength='8'
            minScore='6'
          />
      </>
    );
}

const convertFormData = (objct) => {
  const formData = new FormData();
  Object.keys(objct).forEach(key => formData.append(key, objct[key]));
  return formData;
}

export default function ChangePassword({openDialog, closeDialog}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [pswd, setPswd] = React.useState({
    password:'',
    new_password:'',
    c_new_password:''
  })
  const handleClose = () => { closeDialog(); };
  const handleChangeValue = (e) =>{
    setPswd({
      ...pswd,
      [e.target.name]:e.target.value
    })
  }
  const handleSubmitPswd =()=>{
    if(pswd.password && pswd.new_password && pswd.c_new_password){
      console.log('ALL-ADA');
      if(pswd.new_password === pswd.c_new_password){
        console.log('PASSWORD SAMA');
        requestPost(
          `user/userChangePassword/${JSON.parse(sessionStorage.getItem('userSession')).id}`,
          convertFormData(pswd),
          (resp)=> {
            setAppNoti({...appNoti, openSuccess:true, sucessMsg: resp.status});
            handleClose();
            // direct user to logout
          },
          (loading)=>console.log(loading),
          (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
          'formData'
        );

      } else{
        console.log('PASSWORD Tak SAMA');
      }
    }else {
      console.log('COMPLETE -ALL-1ST!')
    }
  }
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>

          <RenderInputText
              inptLbl='Password'
              inptName='password'
              value={pswd.password}
              InptChange={handleChangeValue}
              type="password"
          />
            
          <RenderPasswordInput
            label='New Password'
            inptName='new_password'
            InptChange={handleChangeValue}
          />
          <RenderPasswordInput
            label='confirm Password'
            inptName='c_new_password'
            InptChange={handleChangeValue}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitPswd} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
