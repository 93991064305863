import React from 'react';

export const MyPointsContext = React.createContext();

export const MyPointsProvider = props =>{
    const [myPointsData, setMyPointsData] = React.useState({
        myPointsList:[],
        matchList:[],
        loading:false,
        slctdYearIdx:0,
        displayPoints:{
            current_year: "",
            data: [],
            summary: {total_points: "", d_from: "", d_to: "", d_expired: ""},
            years: [],
        }
    });
    return(
        <MyPointsContext.Provider value={[myPointsData, setMyPointsData]}>
            {props.children}
        </MyPointsContext.Provider>
    );
};