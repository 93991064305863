import React from "react";
import {
  AppNotiContextProvider,
  AppNotiContext,
} from './AppContext.js'
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LoginPage from './App/Login';
import Register from './App/Login/Components/Register';
import MainPage from './App/Main';
import './App.scss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function RenderErrorDialog(){
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const handleClose=()=>{
    setAppNoti({...appNoti, openErr:false})
  }
  const setErrMsg=()=>{
    let errMessage = [];
    if(appNoti.errObj.data){
      if(typeof appNoti.errObj.data.message === 'object'){
        for (let x in appNoti.errObj.data.message) {
          errMessage.push(<li key={`message${x}`}><h4 style={{margin:0}}>{appNoti.errObj.data.message[x]}</h4></li>);
        };
      } else if(typeof appNoti.errObj.data.message === 'string'){
        errMessage.push(<li key={`errObj}`}><h4 style={{margin:0, textAlign:'center'}}>{appNoti.errObj.data.message}</h4></li>);
      }
    } else if(appNoti.errObj){
      return <li style={{textAlign:'center'}}><h4 style={{margin:0}}>{appNoti.errObj}</h4></li>
    }
    return errMessage;
  }
  return (
    <div>
      <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        open={appNoti.openErr}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{backgroundColor:'#f44336'}} id="form-dialog-title">Oops, There is something wrong..</DialogTitle>
            <DialogContent>
              {appNoti.errObj.status ?
                <h3 style={{textAlign:'center'}}>
                  Code {appNoti.errObj.status}
                  <br/>
                  {appNoti.errObj.statusText ? `Status Message: ${appNoti.errObj.statusText}` : ''}
                </h3>
                :
                ''
              }
              {setErrMsg && <ul style={{listStyleType: 'none', padding:0}}>{setErrMsg()}</ul>}
            </DialogContent>
            <DialogActions>
                <Button
                  onClick={handleClose}
                  type='submit'
                  color="primary"
                >Close
                </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}

function RenderSuccessDialog(){
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const handleClose=()=>{
    setAppNoti({...appNoti, openSuccess:false})
  }
  return (
    <div>
      <Dialog 
        disableBackdropClick
        disableEscapeKeyDown
        open={appNoti.openSuccess}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{backgroundColor:'#289060'}} id="form-dialog-title">
          {/* <h3 style={{margin:0, textAlign:'center', minWidth:'10em'}}>Success</h3> */}
          {appNoti.successTitle ? appNoti.successTitle : <>Success</>}
        </DialogTitle>
        <DialogContent>
          <h3>{appNoti.sucessMsg}</h3>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            type='submit'
            color="primary"
          >Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function App() {
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => sessionStorage.getItem("accessToken") && sessionStorage.getItem("userSession") ? <Component {...props} /> : <Redirect to="/" /> }
    />
  );
  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props => sessionStorage.getItem("accessToken")  && sessionStorage.getItem("userSession") ? <Redirect to="/main" /> : <Component {...props} /> }
    />
  );
  return (
    <section className='app-cont'>
      <AppNotiContextProvider>
        <RenderErrorDialog/>
        <RenderSuccessDialog/>
        <Router>
          <Switch>
            {/* <Route  path={['/', '/main','/:pageType?/:pageAtt?']} component={MainPage} /> */}
            <PublicRoute exact path={['/','/login/:pageAtt?']} component={LoginPage} />
            <PublicRoute exact path={'/register/:pageAtt?'} component={Register} />
            <PrivateRoute path="/main" component={MainPage} />
          </Switch>
        </Router>
      </AppNotiContextProvider>
    </section>
  );
}

export default App;
