import React from 'react';
import {MyPointsContext} from '../src/Context';

export default function MyPoints() {
    const [myPointsData] = React.useContext(MyPointsContext);
    const sumData = myPointsData.displayPoints.summary;
  return (
    <div className='my-info-bar-cont'>
        <h1 style={{fontWeight:400, fontSize:'1.4em'}}>My Point Summary</h1>
        <table className="table-style">
            <thead>
                <tr>
                    <th>Member Since</th>
                    <th>Total Points</th>
                    <th>Last Match Date Served</th>
                    <th>RO Membership Expiry Date</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{sumData.d_from}</td>
                    <td>{sumData.total_points}</td>
                    <td>{sumData.d_to}</td>
                    <td>{sumData.d_expired}</td>
                </tr>
            </tbody>
        </table>
    </div>
  );
}
