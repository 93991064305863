import React from 'react';
import FindInPageRoundedIcon from '@material-ui/icons/FindInPageRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import RenderPointsMenu from './RenderPointsMenu';
import { requestGet, mainDomain } from '../../../../../../utils/httpReq';
import { AdminVerifyContext } from '../../src/Context';
import TableData from '../../../../../../Components/TableData';

export default function FullWidthTabs() {
    const [adminVerifyData, setAdminVerifyData] = React.useContext(AdminVerifyContext);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(()=>{
      requestGet(
          `point/verify_listing`,
          (resp)=>setAdminVerifyData({
            ...adminVerifyData,
            pointsData: resp
          }),
          (loading)=>setIsLoading(loading)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  return (
    <TableData
      title="Points Request Listing"
      data={adminVerifyData.pointsData || []}
      isLoading={isLoading}
      options={{ 
        pageSize: 10,
      }}
      columns={[
          {
              title: 'IROA/NROI ID',
              field: 'iroa_id'
          },
          {
              title: 'Name',
              field: 'name'
          },
          {
              title: 'Roles',
              field: 'roles'
          },
          {
              title: 'Match Name',
              field: 'match_name'
          },
          {
              title: 'Match Date',
              render: rowData=> <>{rowData.date_from}-{rowData.date_to}</>
          },
          {
              title: 'Level',
              field: 'level'
          },
          {
              title: 'Points',
              field: 'points'
          },
          {
              title: 'File',
              field: 'file',
              sorting: false,
              render: rowData=> rowData.file && rowData.file !== 'null' ? 
                          <a
                              className='tbl-active-icon view'
                              href={`${mainDomain}storage/${rowData.file}`}
                              target='_blank' rel='noopener noreferrer'
                          >
                              <FindInPageRoundedIcon/>
                              <p><small>View</small></p>
                          </a>
                      :
                          <div className='tbl-active-icon na'>
                              <BlockRoundedIcon/>
                              <p><small>N/A</small></p>
                          </div>
          },
          {
              title: 'Actions',
              sorting: false,
              render: rowData=> <RenderPointsMenu userID={rowData.point_id}/>
          },
        ]}
    />
  );
}
