import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {requestPost} from '../../../utils/httpReq';
import ImageDropZone from '../../../Components/ImageDropZone';
import PasswordForm from './Components/PasswordForm';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import {AppNotiContext} from '../../../../AppContext.js'
import {convertFormData} from '../../../utils'
import {
  RenderInputText,
  MyCardInput,
} from '../../../Components/RenderInput';
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  }
}));
export default function SignIn({history}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const classes = useStyles();
  const [openPswd, setOpenPswd] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [formObj, setFormObj] = React.useState({
    name:'',
    nric:'',
    contact_num:'',
    email:'',
    password:'',
    file:'',
  })
  const handleResp =(resp)=>{
    if(resp.status !== 'error'){
      setAppNoti({...appNoti, openSuccess:true, sucessMsg: 'Account Registered, Your Application will be Processed by Admin.'})
      history.push('/login');
    } else alert('Data has error, Please check your format')
  };
  const handleCangeInput=(e)=> {
    setFormObj({
      ...formObj,
      [e.target.name]: e.target.value
    });
  }
  const sentReq=()=>{
    requestPost(
      'register',
      convertFormData(formObj),
      (resp)=> handleResp(resp),
      (loading)=>setFetching(loading),
      (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
      'formData'
    );
  }
  const submitThis = (e)=>{
    e && e.preventDefault();
    if(formObj.name && formObj.nric && formObj.contact_num && formObj.email && formObj.file){
      if(!formObj.password){
        setOpenPswd(true);
      } else sentReq();
    } else{
      setAppNoti({...appNoti, openErr:true, errObj: 'Please complete all required fields to proceed.'})
    }
  }
  return (
    <div className='auth-cont'>
      {fetching ? <LoadingSpinner /> : ''}
      <Container component="main" maxWidth="xs">
        <Paper elevation={3} style={{padding:'0 1.3em 2em'}}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <GroupAddOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register Account
          </Typography>
          <form className={classes.form} onSubmit={submitThis} noValidate>
              <RenderInputText
                inptLbl='Name'
                inptName='name'
                value={formObj.name}
                InptChange={handleCangeInput}
              />
              <MyCardInput
                value={formObj.nric}
                onChangeVal={(val)=>setFormObj({ ...formObj, nric: val })}
                name='nric'
              />
              <RenderInputText
                inptLbl='Phone Number'
                inptName='contact_num'
                value={formObj.contact_num}
                InptChange={handleCangeInput}
              />
              <RenderInputText
                inptLbl='Email'
                inptName='email'
                value={formObj.email}
                InptChange={handleCangeInput}
              />
            <ImageDropZone
              setUploadedFile={(file)=> setFormObj({ ...formObj, file: file[0] })}
              btnTxt='UPLOAD RO CERTIFICATE'
            />
            <PasswordForm
              dialogStatus={openPswd}
              closeDialog={()=>setOpenPswd(false)}
              pswdVal = {(val)=>setFormObj({ ...formObj, password: val })}
              submitForm={()=>{
                setOpenPswd(false);
                submitThis();
              }}
            />
            <div style={{textAlign:'center', marginTop:'2em'}}>
              <Button
                onClick={()=>history.push('/login')}
                variant="outlined"
                color="secondary"
              >Back
              </Button>
              <Button
                style={{marginLeft:'1em'}}
                type="submit"
                variant="contained"
                color="primary"
              >Proceed
              </Button>
            </div>
          </form>
        </div>
      </Paper>
      </Container>
    </div>
  );
}