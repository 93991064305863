import React from 'react';
import {
  RenderInputText,
  RenderDatePicker,
  RenderInputSlct,
  AnimatedMultiSlct,
} from '../../../../../Components/RenderInput';
import ImageDropZone from '../../../../../Components/ImageDropZone';
import { format } from 'date-fns';
import {
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import {MatchContext} from '../src/Context';
import './src/css/creatematchdialog.scss'

const slctOpt = [
  {lbl: 'I', val: '1'},
  {lbl: 'II', val: '2'},
  {lbl: 'III', val: '3'},
  {lbl: 'IV', val: '4'},
  {lbl: 'V', val: '5'},
  {lbl: 'VI', val: '6'},
  {lbl: 'VII', val: '7'},
];

export default function RenderCreateMatchForm({isCreateMatch, formData, editData}){
  const [appMatch] = React.useContext(MatchContext);
  const [formInput, setFormInput] = React.useState({
    name:'',
    region:'',
    date_from: format(new Date(),'yyyy-MM-dd'),
    date_to:format(new Date(),'yyyy-MM-dd'),
    status: 1,
    point: 0,
  });
  const handleCangeInput=(e)=> {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value
    });
  }
  const handleCangeInputVal=(inptName, inptVal)=> {
    setFormInput({
      ...formInput,
      [inptName]: inptVal
    });
  }
  React.useEffect(()=>{
    formData(formInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formInput])
  React.useEffect(()=>{
    if(editData) setFormInput(editData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editData])
  return(
    <div className='form-cont'>
    {isCreateMatch ?
        <>
            <h4>Match Details</h4>
            <div>
                <RenderInputText
                    inptLbl='Match Name'
                    inptName='name'
                    inptVal={formInput.name || ''}
                    InptChange={handleCangeInput}
                />
            </div>
            <div>
                <RenderInputText
                    inptLbl='Region'
                    inptName='region'
                    inptVal={formInput.region || ''}
                    InptChange={handleCangeInput}
                />
            </div>
            <div>
                <RenderDatePicker
                    dateLbl='Active From'
                    dateVal={formInput.date_from}
                    setDateVal={(val)=>handleCangeInputVal('date_from', val)}
                />
                <RenderDatePicker
                    dateLbl='Active to'
                    dateVal={formInput.date_to}
                    setDateVal={(val)=>handleCangeInputVal('date_to', val)}
                />
            </div>
            <div>
                <RenderInputSlct
                  slctLbl= 'Level'
                  slctName= 'point'
                  slctVal= {formInput.point}
                  slctList={slctOpt}
                  slctListLbl={{val:'val',lbl:'lbl'}}
                  slctOnChange={(val)=>handleCangeInputVal('point', val)}
                />
                <RenderInputText
                  inptLbl='Points'
                  inptName='official_doc'
                  inptVal={formInput.point || 0}
                  disabled
                />
            </div>
            <div>
                <ImageDropZone
                  setUploadedFile={(file)=>handleCangeInputVal('file', file[0])}
                  btnTxt='Upload Match Official List'
                />
            </div>
            <div>
                <FormControlLabel
                    style={{marginLeft: '70%'}}
                    control={
                        <Switch
                          checked={!!parseInt(formInput.status)}
                          onChange={(e)=>handleCangeInputVal('status', e.target.checked ? 1 : 0)}
                          name="status"
                          color="primary"
                        />
                    }
                    label={formInput.status ? 'Active' : 'Inactive'}
                />
            </div>
        </>
        :
        <>
          <h4>Member Listing</h4>
          <div>
            <AnimatedMultiSlct
              multipleOpt={appMatch.userList}
              onSelect={(val)=> setFormInput(val)}
              selectedVal={formInput}
            />
          </div>
        </>
      }
    </div>
  );
}