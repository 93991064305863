import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { requestPost } from '../../../../../../utils/httpReq';
import { AdminVerifyContext } from '../../src/Context';
import { MainContext } from '../../../../../src/Context';
import { AppNotiContext } from '../../../../../../../AppContext.js';

const ITEM_HEIGHT = 48;

export default function LongMenu({userID}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminVerifyData, setAdminVerifyData] = React.useContext(AdminVerifyContext);
  const [mainContextData, setMainContextData] = React.useContext(MainContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <div>
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
        > <ExpandMoreIcon />
        </IconButton>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
            style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
            },
            }}
        >
            <MenuItem onClick={(e)=>{
                handleClose(e);
                requestPost(
                  `user/verify/${userID}`,
                  '',
                  (resp)=> {
                    setAdminVerifyData({
                    ...adminVerifyData,
                    verifyListing: resp.data
                   });
                   setAppNoti({...appNoti, openSuccess:true, sucessMsg: resp.status})
                  },
                  (loading)=> setMainContextData({...mainContextData,loading: loading}),
                  (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
                  'formData'
                );
            }}>Approve</MenuItem>
            <MenuItem onClick={(e)=>{
                handleClose(e);
                requestPost(
                  `user/reject/${userID}`,
                  '',
                  (resp)=>{
                    setAdminVerifyData({
                      ...adminVerifyData,
                      verifyListing: resp.data
                    });
                    setAppNoti({...appNoti, openSuccess:true, sucessMsg: resp.status})
                  },
                  (loading)=> setMainContextData({
                    ...mainContextData,
                    loading: loading
                  }),
                  (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
                  'formData'
                );
            }}>Reject</MenuItem>
        </Menu>
    </div>
  );
}
