import axios from 'axios';
const domainLink = 'https://nroiprs.com/public/api/';
// const domainLink = 'http://127.0.0.1:8000/api/';


export const mainDomain = 'https://nroiprs.com/'


export const requestGet = async (
  reqLink,
  respData,
  setLoading,
  respError
  )=>  {
  setLoading(true);
  try {
    // console.log('reqGet==>', reqLink);
    await axios
      .get(
        `${domainLink}${reqLink}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
          }
        }
      )
      .then((resp) => {
          if(resp.status === 200){
            respData(resp.data);
          } else {
            console.log('fetch error', resp);
          }
      });
  } catch (e){
    console.log('e.response==>',e.response, e.response.data.message)
      if(e.response.status === 401) window.location.replace('/');
      else alert('Failed To Fetch: '+e.response.status);
  } finally{
    setLoading(false);
  }
}

export const requestPost = async (reqLink, postObj, respData, setLoading, respError, opt)=>  {
  setLoading(true);
  try {
    await axios
      .post(
        `${domainLink}${reqLink}`,
        postObj,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Content-Type': opt === 'formData' ? 'multipart/form-data' : ''
          }
        }
      )
      .then((resp) => {
        // console.log('resp.status==>', resp.status)
        if(resp.status === 200 || resp.status === 201){
            respData(resp.data);
        } else {
            console.log('fetch error', resp);
        }
      });
  } catch (e){
    console.log('requestPostError==>', e)
      if(e.response.status === 401) window.location.replace('/');
      else respError(e.response);
      // else alert('Failed To Fetch: '+e.response.status);
      // alert('Unauthorized Access status: '+e.response.status);
  } finally{
    setLoading(false);
  }
}
