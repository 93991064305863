import React from 'react';
// import { format } from 'date-fns';
import { RenderInputText } from '../../../../../Components/RenderInput';

const levelList=(lvl)=>{
  switch (lvl) {
    case '1': return 'I';
    case '2': return 'II';
    case '3': return 'III';
    case '4': return 'IV';
    case '5': return 'V';
    case '6': return 'VI';
    case '7': return 'VII';
    default: return 'N/A';
  }
}

export default function RenderMatchDetails({details}){
  return(
    <>
      <div>
        <RenderInputText
          disabled
          inptLbl='Match Date From'
          inptName='match_date'
          inptVal={details ? details.date_from : 'N/A'}
          // inptVal={details ? format(new Date(details.date_from),'P') : 'N/A'}
        />
        <RenderInputText
          disabled
          inptLbl='Match Date To'
          inptName='match_date'
          // inptVal={details ? format(new Date(details.date_to),'P') : 'N/A'}
          inptVal={details ? details.date_to : 'N/A'}
        />
      </div>
      <div>
        <RenderInputText
          disabled
          inptLbl='Match Region'
          inptName='match_date'
          inptVal={details ? details.region : 'N/A'}
        />
      </div>
      <div>
        <RenderInputText
          disabled
          inptLbl='Level'
          inptName='level'
          inptVal={details ? levelList(details.points || details.point) : 'N/A'}
        />
        <RenderInputText
          disabled
          inptLbl='Points'
          inptName='point'
          inptVal={details ? (details.points || details.point) : 'N/A'}
        />
      </div>
      </>
  );
}