import React from 'react';
import { withRouter } from "react-router-dom";
import {
  Paper,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import FindInPageRoundedIcon from '@material-ui/icons/FindInPageRounded';
import Button from '@material-ui/core/Button';
import {AppNotiContext} from '../../../../../AppContext.js';
import {requestGet, requestPost, mainDomain} from '../../../../utils/httpReq';
import LoadingSpinner from '../../../../Components/LoadingSpinner'
import './src/css/profileStyle.scss';
import {
  RenderInputSlct,
  RenderInputText,
  RenderDatePicker,
  MyCardInput,
} from '../../../../Components/RenderInput';
import UploadProfilePict from './Components/UploadProfilePict';
import GenerateIDcard from './Components/GenerateIDcard';
import DeleteUser from './Components/DeleteUser';
const slctOpt = [
  {lbl: 'RM', val: 'RM'},
  {lbl: 'SO', val: 'SO'},
  {lbl: 'CRO', val: 'CRO'},
  {lbl: 'RO', val: 'RO'},
];
function ProfilePage({match, adminView, closeDialog}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [isEditRank, setIsEditRank] = React.useState(false);
  const uid = adminView || JSON.parse(sessionStorage.getItem('userSession')).id;
  const [editForm, setEditFOrm] = React.useState(true);
  const [userData, setUserData] = React.useState({
    name:'',
    nric:'',
    d_birth:'',
    d_join:'',
    email:'',
    contact_num:'',
    home_num:'',
    address1:'',
    city:'',
    state:'',
    postcode:'',
    country:'',
    rank_stat:'',
    nat_rank_stat:'',
    size:'',
    nok_name: '',
    nok_contact_num: '',
    i_disabled: 0,
  });
  const [fetchData, setFetchData] = React.useState(false);
  const convertFormData = (objct) => {
    const formData = new FormData();
    Object.keys(objct).forEach(key => objct[key] && formData.append(key, objct[key]));
    return formData;
  }
  const filteredData={
    name: userData.name,
    images: userData.profile_pict,
    nric: userData.nric,
    iroa_id: userData.iroa_id,
    d_birth: userData.d_birth,
    d_join: userData.d_join,
    email: userData.email,
    contact_num: userData.contact_num,
    home_num: userData.home_num,
    address1: userData.address1,
    city: userData.city,
    state: userData.state,
    postcode: userData.postcode,
    country: userData.country,
    rank_stat: userData.rank_stat,
    nat_rank_stat: userData.nat_rank_stat,
    size: userData.size,
    nok_name: userData.nok_name,
    nok_contact_num: userData.nok_contact_num,
    i_disabled: userData.i_disabled,
  };
  const handlePostData=(e)=>{
    e.preventDefault();
    if(editForm){
      setEditFOrm(false)
    }else{
      requestPost(
        `user/userEdit/${uid}`,
        convertFormData(filteredData),
        (resp)=> {
          setUserData(resp.data);
          setAppNoti({...appNoti, openSuccess:true, sucessMsg: 'Profile updated successfully!'})
          setEditFOrm(true);
        },
        (loading)=>setFetchData(loading),
        (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
      )
    }
  }
  const handleCangeInput=(e)=> {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  }
  React.useEffect(()=>{
    requestGet(
      `user/show/${uid}`,
      (resp)=>setUserData(resp),
      (loading)=>setFetchData(loading)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const editProfileRank=()=>{
    if(isEditRank){
      requestPost(
        `user/userEdit/${uid}`,
        convertFormData(filteredData),
        (resp)=> {
          setUserData(resp.data);
          setAppNoti({...appNoti, openSuccess:true, sucessMsg: 'Profile updated successfully!'})
          setIsEditRank(false);
        },
        (loading)=>setFetchData(loading),
        (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
      )
    } else setIsEditRank(true);
  }
  const renderRankStatus=()=>{
    if(userData.i_updated_rank === '1') return <h4>Rank<small style={{color:'#c34d4d', fontSize:'.7em'}}> (Pending Approval)</small></h4>;
    else if(userData.i_updated_rank === '0' && userData.updated_rank_at ) return <h4>Rank<small style={{color:'#4ab94a', fontSize:'.7em'}}> (Verified)</small></h4>;
    return <h4>Rank</h4>;
  }
  return (
    <>
      {fetchData ? <LoadingSpinner/> : '' }
      {/* <Container component='main' maxWidth='lg'> */}
        <Paper elevation={3} style={{padding:'.3em 1.3em 2em'}}>
          <h1 style={{fontWeight:400, fontSize:'1.4em'}}>My Profile</h1>
          <UploadProfilePict
            disabled={editForm}
            picVal= {userData.images}
            setPicVal={(val)=>{
              setUserData({
                ...userData,
                profile_pict: val
              });
            }}
          />
          <form onSubmit={handlePostData} noValidate>
            <div className='form-cont'>
              <h4 style={{marginTop:0}}>Profile</h4>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Name'
                  inptName='name'
                  inptVal={userData.name || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <div>
                <MyCardInput
                  disabled
                  value={userData.nric || ''}
                  name='profileMycard'
                  onChangeVal={()=>console.log(' ')}
                />
                <RenderDatePicker
                  disabled
                  dateLbl='DOB'
                  dateVal={userData.d_birth}
                  setDateVal={(val)=>
                    setUserData({
                      ...userData,
                      d_birth: val
                  })}
                />
              </div>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Gender'
                  inptName='gender'
                  inptVal={userData.gender || ''}
                  InptChange={handleCangeInput}
                />
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Shirt Size'
                  inptName='size'
                  inptVal={userData.size || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <h4>Contact Details</h4>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Email'
                  inptName='email'
                  inptVal={userData.email || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Handphone Number'
                  inptName='contact_num'
                  inptVal={userData.contact_num || ''}
                  InptChange={handleCangeInput}
                />
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Home Number'
                  inptName='home_num'
                  inptVal={userData.home_num || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Address'
                  inptName='address1'
                  inptVal={userData.address1 || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='City'
                  inptName='city'
                  inptVal={userData.city || ''}
                  InptChange={handleCangeInput}
                />
                <RenderInputText
                  disabled={editForm}
                  inptLbl='State'
                  inptName='state'
                  inptVal={userData.state || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Poscode'
                  inptName='postcode'
                  inptVal={userData.postcode || ''}
                  InptChange={handleCangeInput}
                />
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Country'
                  inptName='country'
                  inptVal={userData.country || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <h4>Emergency Contact Profile</h4>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Name'
                  inptName='nok_name'
                  inptVal={userData.nok_name || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <div>
                <RenderInputText
                  disabled={editForm}
                  inptLbl='Phone Number'
                  inptName='nok_contact_num'
                  inptVal={userData.nok_contact_num || ''}
                  InptChange={handleCangeInput}
                />
              </div>
              <h4>IROA/NROI Member Record</h4>
              <div>
                <RenderInputText
                  disabled={!isEditRank}
                  InptChange={handleCangeInput}
                  inptLbl='IROA/NROI ID'
                  inptName='iroa_id'
                  inptVal={userData.iroa_id || ''}
                />
                <RenderInputText
                  disabled
                  inptLbl='Total Points'
                  inptName='total_points'
                  inptVal={userData.total_points || ''}
                />
              </div>
              <div>
              <RenderDatePicker
                  disabled={editForm}
                  dateLbl='Member Since'
                  dateVal={userData.d_join}
                  setDateVal={(val)=>
                    setUserData({
                      ...userData,
                      d_join: val
                  })}
                />
                <RenderInputText
                  disabled
                  inptLbl='Current Year'
                  inptVal={userData.currentYear || ''}
                />
              </div>
              {/*  */}
                {renderRankStatus()}
              {/*  */}
              <div>
                <div>
                  <RenderInputSlct
                    disabled={editForm}
                    slctLbl= 'IROA Rank'
                    slctName= 'rank_stat'
                    slctVal= {userData.i_updated_rank === '1' ? userData.new_rank_stat : userData.rank_stat}
                    slctList={slctOpt}
                    slctListLbl={{val:'val',lbl:'lbl'}}
                    slctOnChange={(val)=>
                      setUserData({
                        ...userData,
                        rank_stat: val
                      })}
                  />
                </div>
                <div>
                  <RenderInputSlct
                    disabled={editForm}
                    slctLbl= 'NROI Rank'
                    slctName= 'nat_rank_stat'
                    slctVal= {userData.i_updated_rank === '1' ? userData.new_nat_rank_stat : userData.nat_rank_stat}
                    slctList={slctOpt}
                    slctListLbl={{val:'val',lbl:'lbl'}}
                    slctOnChange={(val)=>
                      setUserData({
                        ...userData,
                        nat_rank_stat: val
                      })}
                  />
                </div>
              </div>
              {!!adminView &&
                <div>
                  <div>
                    <FormControlLabel
                      disabled={editForm}
                      control={
                          <Switch
                            checked={!parseInt(userData.i_disabled)}
                            onChange={(e)=>{
                              setUserData({
                                ...userData,
                                i_disabled: e.target.checked ? "0" : "1"
                              })
                            }}
                            name="i_disabled"
                            color="primary"
                          />
                      }
                      label={`User Account ${!parseInt(userData.i_disabled) ? ' Active' : ' Disabled'}`}
                    />
                    {!!parseInt(userData.i_disabled) &&
                      <p style={{color:'red', marginTop:0}}>
                        <small>
                          *User are restricted from login into this profile.
                        </small>
                      </p>
                    }
                    </div>
                      <DeleteUser uid={uid} isDisabled={editForm} closeDialog={()=>closeDialog()}/>
                  </div>
              }
                <div>
                  <div/>
                  <Button
                      size='small'
                      color='primary'
                      style={{margin:'1em .3em'}}
                      disabled={!userData.file}
                      onClick={()=>window.open(`${mainDomain}${userData.file}`)}
                    > <FindInPageRoundedIcon/> View Uploaded RO Certificate
                    </Button>
                </div>
                <div style={{textAlign:'right', display:'block', padding:'2em 0'}}>
                  <GenerateIDcard
                    cardData={userData}
                  />
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                  > {editForm ? 'Edit' : 'Save'} Profile
                  </Button>
                  &nbsp;
                    {!editForm &&
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={()=>{ setEditFOrm(true) }}
                      > Cancel
                      </Button>
                    }
                </div>
            </div>
          </form>
        </Paper>
      {/* </Container> */}
    </>
  );
}

export default  withRouter(ProfilePage);