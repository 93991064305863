import React from 'react';
import { withRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Profile from '../../Profile';
import UserPoints from '../../Points';

function AdminView({openDetails, closeDetails, openType, uid}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    closeDetails();
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <React.Fragment>
      <Dialog
        open={openDetails}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="adminProfile"
      >
        <DialogTitle id="scroll-dialog-title">{openType === 'profile' ? 'Member Profile' : 'Member Points'}</DialogTitle>
        <DialogContent dividers>
          {openType === 'profile' ?
            <Profile adminView={uid} closeDialog={handleClose}/>
            :
            <UserPoints uid={uid} closeDialog={handleClose}/>
          }
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withRouter(AdminView);
