import React from 'react';
import MoreOptions from '../Components/MoreOptions';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TableData from '../../../../../Components/TableData';
import {
    sortStringDate,
    isUserAdmin,
    sortPoint,
} from '../../../../../utils';

export default function RenderTabContent({dataVal, isLoading}){
    let totalPoints = 0;
    dataVal.map((itm)=>{
        if(itm.i_confirmed === '1') totalPoints += parseFloat(itm.points);
        return itm;
    })
    return(
        <>
            <TableData
                title="My Match Listing"
                data={dataVal || []}
                isLoading={isLoading}
                columns={[
                    {
                        title: 'Match Name',
                        field: 'name'
                    },
                    {
                        title: 'Date From',
                        field: 'date_from',
                        customSort: (a, b) => sortStringDate(a.date_from,b.date_from)
                    },
                    {
                        title: 'Date To',
                        field: 'date_to',
                        customSort: (a, b) => sortStringDate(a.date_to,b.date_to)
                    },
                    {
                        title: 'Region',
                        field: 'region'
                    },
                    {
                        title: 'Role',
                        field: 'roles'
                    },
                    {
                        title: 'Points',
                        field: 'points',
                        render: rowData=> (
                            <>
                                {rowData.points}
                                {rowData.i_cancel === '1' ?
                                    <div style={{display:'flex', alignItems:'center', color:'#ff725b'}}>
                                        <HighlightOffIcon style={{fontSize:'1em'}}/>
                                        <p style={{fontSize:'.7em',margin:0, fontWeight:700, lineHeight:1.2}}>Admin<br/>Reject</p>
                                    </div>
                                    :
                                    ''
                                }
                                {rowData.i_confirmed === '1' ?
                                    <div style={{display:'flex', alignItems:'center', color:'#66AB21'}}>
                                        <CheckCircleOutlineIcon style={{fontSize:'1em'}}/>
                                        <p style={{fontSize:'.7em',margin:0, fontWeight:700, lineHeight:1.2}}>Points<br/>Approved</p>
                                    </div>
                                    :
                                    ''
                                }
                                {rowData.i_confirmed === '0' && rowData.i_cancel === '0' ?
                                    <div style={{display:'flex', alignItems:'center', color:'#B5B8BA'}}>
                                        <HourglassEmptyIcon style={{fontSize:'1em'}}/>
                                        <p style={{fontSize:'.7em',margin:0, fontWeight:700, lineHeight:1.2}}>Pending<br/>Approval</p>
                                    </div>
                                    :
                                    ''
                                }
                            </>
                        ),
                        customSort: (a, b) => sortPoint(parseFloat(a.points),parseFloat(b.points))
                    },
                    {
                        title: 'Actions',
                        sorting: false,
                        hidden: isUserAdmin(),
                        render: rowData=> <MoreOptions pointDetails={rowData} isDisabled={rowData.i_confirmed === "1"} />
                    },
                ]}
                components={{
                Toolbar: props => (
                    <div style={{display:'flex', width:'100%', alignItems:'center'}}>
                        <h1 style={{fontWeight:400, fontSize:'1.4em', margin: '0 0 .5em',}}>
                            My Match Listing  
                        </h1>
                        {!!totalPoints ? 
                            <h3 style={{ fontSize:'1em', margin: '0 0 .5em', textAlign:'right', flex:1}}>
                                Total Approved Points {totalPoints.toFixed(2)}
                            </h3>
                            : ''
                        }
                    </div>
                ),
                }}
                // options={{
                //     rowStyle: rowData => ({ 
                //         backgroundColor: rowData.i_cancel === '1' ? '#ff725b' : '',
                //         color: rowData.i_cancel === '1' ? '#fff' : '',
                //     })
                //   }}
            />
            
        </>
    );
}
