import React from 'react';
import './src/css/loadingSpinner.scss';

export default function LoadingSpinner() {
  return (
      <div className='loading-spin-cont'>
            <div className='spin-shade'/>
            <div className='spinner-cont'>
                <div className="revolver">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                </div>
                <h1>Reloading Data...</h1>
            </div>
      </div>
  );
}