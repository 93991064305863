import React from 'react';
import { requestGet } from '../../../../utils/httpReq';
import {
  Paper,
  Button,
} from '@material-ui/core';
import CreateMatchDialog from './Components/CreateMatchDialog';
import { MatchContextProvider, MatchContext } from './src/Context';
import RenderMatchTables from './Components/RenderMatchTable';

function RenderMatchTable(){
  const [appMatch, setMatch] = React.useContext(MatchContext);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(()=>{
    setIsLoading(true);
    requestGet(
      `match/listing`,
      (resp)=>setMatch({...appMatch, matchList: resp}),
      (loading)=> setIsLoading(loading),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return(
      <RenderMatchTables tableData={appMatch.matchList} isLoading={isLoading}/>
  );
}

export default function LoginPage() {
  const [matchDialog, setMatchDialog] = React.useState(false);
  return (
    <MatchContextProvider>
      {/* <Container component='main' maxWidth='lg'> */}
        <Paper elevation={3} style={{padding:'1em 1em 2em'}}>
          <CreateMatchDialog
            openDialog={matchDialog}
            closeDialog={()=>setMatchDialog(false)}
          />
          <div style={{textAlign:'right', margin:'1em'}}>
            <Button variant="contained" color="primary" onClick={()=>setMatchDialog(true)}>
              Create Match
            </Button>
          </div>
          <RenderMatchTable/>
        </Paper>
      {/* </Container> */}
    </MatchContextProvider>
  );
}