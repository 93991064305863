import React from 'react';

export const AdminVerifyContext = React.createContext();
export const AdminVerifyProvider = props =>{
    const [adminVerifyData, setAdminVerifyData] = React.useState({
        slctdUser:'',
        dialogUpdate: false,
        verifyListing:[],
    });

    return(
        <AdminVerifyContext.Provider value={[adminVerifyData, setAdminVerifyData]}>
            {props.children}
        </AdminVerifyContext.Provider>
    );
};