import React from 'react';
import {
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import makeAnimated from 'react-select/animated';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import './renderInput.scss';
import {AppNotiContext} from '../../../AppContext.js';

export function RenderInputSlct({slctLbl, slctName, slctVal, slctList, slctListLbl, slctOnChange, disabled}){
  return(
      <FormControl disabled={!!disabled} fullWidth margin='dense' variant="outlined" >
        <InputLabel id={slctName}>{slctLbl}</InputLabel>
        <Select
          labelId={slctName}
          value={slctVal || ''}
          onChange={(e)=>slctOnChange(e.target.value)}
          label={slctLbl}
        >
          <MenuItem value=""> <em>Select One</em></MenuItem>
          {slctList.map((val, indx)=> <MenuItem key={indx} value={val[slctListLbl.val]}>{val[slctListLbl.lbl]}</MenuItem>)}
        </Select>
      </FormControl>
  );
}

export function RenderInputText({inptLbl, inptName,inptVal,InptChange, disabled , type}){
  return(
    <TextField
      disabled={!!disabled}
      size='small'
      variant='outlined'
      margin='dense'
      fullWidth
      label={inptLbl}
      name={inptName}
      value={inptVal}
      onChange={InptChange}
      type={type || 'text'}
    />
  );
}

export function RenderDatePicker({dateVal,setDateVal, dateLbl, disabled}){
  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        disabled={!!disabled}
        disableFuture
        autoOk
        fullWidth
        variant="dialog"
        inputVariant="outlined"
        margin="dense"
        label={dateLbl}
        value={dateVal}
        onChange={(e)=>setDateVal(format(e,'yyyy-MM-dd'))}
        format="dd/MM/yyyy"
      />
    </MuiPickersUtilsProvider>
  );
}

export const MyCardInput = ({disabled, value, onChangeVal, name}) => (
  <NumberFormat
    disabled={disabled}
    value={value}
    onValueChange={(val)=>onChangeVal(val.value)}
    customInput={TextField}
    format="######-##-####"
    size='small'
    name={name || ''}
    label='NRIC'
    variant='outlined'
    margin='dense'
    fullWidth
  />
);

export function AnimatedMultiSlct({multipleOpt, onSelect, selectedVal}) {
  const animatedComponents = makeAnimated();
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  return (
    <ReactSelect
      value={selectedVal.length ? selectedVal : []}
      styles={{ menu: provided => ({ menuPortal: base => ({ ...base, zIndex: 9999 })  })}}
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      options={multipleOpt}
      onChange={
        (val)=>{
          if(val) onSelect(val);
          else setAppNoti({...appNoti, openErr:true, errObj: <>Member listing cannot be empty,<br/>The listing must have at least 1 member.</>});
        }
      }
      className='animated-multi-cont'
    />
  );
}