import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FindInPageRoundedIcon from '@material-ui/icons/FindInPageRounded';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import BlockIcon from '@material-ui/icons/Block';
import LongMenu from './RenderLongMenu';
import TableData from '../../../../../Components/TableData';
import { mainDomain } from '../../../../../utils/httpReq';
import { sortStringDate } from '../../../../../utils';

export default function RenderTable({tableData, isLoading}) {
    return (
      <TableData
        title="Match Listing"
        isLoading={isLoading}
        options={{ 
          pageSize: 10,
        }}
        columns={[
            {
                title: 'Name',
                field: 'name'
            },
            {
                title: 'Date From',
                field: 'date_from',
                customSort: (a, b) => sortStringDate(a.date_from,b.date_from)
            },
            {
                title: 'Date To',
                field: 'date_to',
                customSort: (a, b) => sortStringDate(a.date_to,b.date_to)
            },
            {
                title: 'Region',
                field: 'region'
            },
            {
                title: 'Status',
                field: 'status',
                render: rowData => rowData.status === '1' ?
                    <div className='tbl-active-icon'>
                      <CheckCircleOutlineIcon/>
                      <p><small>Active</small></p>
                    </div>
                    :
                    <div className='tbl-active-icon notActive'>
                      <BlockIcon/>
                      <p><small>Inactive</small></p>
                    </div>,
            },
            {
                title: 'File',
                field: 'rank_stat',
                sorting: false,
                render: rowData=> rowData.file && rowData.file !== 'null' ? 
                            <a
                                className='tbl-active-icon view'
                                href={`${mainDomain}storage/${rowData.file}`}
                                target='_blank' rel='noopener noreferrer'
                            >
                                <FindInPageRoundedIcon/>
                                <p><small>View</small></p>
                            </a>
                        :
                            <div className='tbl-active-icon na'>
                                <BlockRoundedIcon/>
                                <p><small>N/A</small></p>
                            </div>
            },
            {
                title: 'Actions',
                sorting: false,
                render: rowData=> <LongMenu matchId={rowData.id} />
            },
          ]}
        data={tableData || []}
      />
    )
  }