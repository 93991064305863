import React from 'react';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Typography,
  IconButton,
  Container,
  ListItem,
  Tooltip,
  Toolbar,
  Divider,
  Button,
  Drawer,
  AppBar,
  List,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import GpsFixedOutlinedIcon from '@material-ui/icons/GpsFixedOutlined';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import GamepadOutlinedIcon from '@material-ui/icons/GamepadOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChangePassword from './Components/ChangePassword';
// import HomeIcon from '@material-ui/icons/Home';
import './src/css/headerStyle.scss';
import nroiLogo from '../../../src/img/nroilogo.png';
import {isUserAdmin} from '../../../utils';

function DrawerContent({onCloseSm}){
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const checkActive =(item)=>location.pathname === item;
  const handleLogout =()=>{ sessionStorage.clear(); history.push('/'); }
  const userData = JSON.parse(sessionStorage.getItem('userSession'));
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  return(
    <>
    <ChangePassword
      openDialog={openChgPwd}
      closeDialog={()=>setOpenChgPwd()}
    />
    <div className={classes.toolbar}>
      <div className={`logo-cont`}>
        <img src={nroiLogo} alt='nroiLogo'/>
        {isUserAdmin() ? 
        <h3>NROI Admin</h3>
        :
          <div style={{textAlign:'center', paddingBottom:'1em'}}>
            <p style={{margin:0}}><b><small>{userData.name}</small></b></p>
            <p style={{margin:0}}><b><small>NROI ID: {userData.nroa_id}</small></b></p>
            {userData.rank_stat ? <p style={{margin:0}}><b><small>Rank: {userData.rank_stat}</small></b></p> : ''}
            <div style={{width:'100%', textAlign:'center', margin:'.5em 0 0'}}>
              <Button
                style={{textTransform:'none', fontWeight:'700', padding:'0 .3em', fontSize:'.6em'}}
                variant="outlined"
                color='primary'
                size='small'
                onClick={()=>setOpenChgPwd(true)}
              >Change Password 
              </Button>
              <Tooltip title="User Manual" aria-label="User Manual" arrow>
                <IconButton
                  aria-label="Login Info"
                  size='small'
                  color='secondary'
                  onClick={()=>{
                    window.open('https://www.nroiprs.com/src/doc/NROIPRSMemberManual.pdf', '_blank');
                    onCloseSm();
                  }}
                >
                  <HelpOutlineOutlinedIcon/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        }
      </div>
    </div>
    <Divider />
    <List>
      {isUserAdmin() ? 
        <div>
          <ListItem
            button
            onClick={()=>{
              history.push('/main/members');
              onCloseSm();
            }}
            selected={checkActive('/main/members')}
          >
            <ListItemIcon><PeopleOutlineIcon /></ListItemIcon>
            <ListItemText primary='Members' />
          </ListItem>
          <ListItem
            button
            onClick={()=>{
              history.push('/main/mymatches');
              onCloseSm();
            }}
            selected={checkActive('/main/mymatches')}
          >
            <ListItemIcon><GamepadOutlinedIcon /></ListItemIcon>
            <ListItemText primary='Matches' />
          </ListItem>
          <ListItem
            button
            onClick={()=>{
              history.push('/main/verifications');
              onCloseSm();
            }}
            selected={checkActive('/main/verifications')}
          >
            <ListItemIcon><HowToRegOutlinedIcon /></ListItemIcon>
            <ListItemText primary='Verification' />
          </ListItem>
        </div>
        :
        <div>
          <ListItem
            button
            onClick={()=>{
              history.push('/main/myprofile');
              onCloseSm();
            }}
            selected={checkActive('/main/myprofile')}
          >
            <ListItemIcon><AccountCircleOutlinedIcon /></ListItemIcon>
            <ListItemText primary='My Profile' />
          </ListItem>
          <ListItem
            button
            onClick={()=>{
              history.push('/main/mypoints');
              onCloseSm();
            }}
            selected={checkActive('/main/mypoints')}
          >
            <ListItemIcon><GpsFixedOutlinedIcon /></ListItemIcon>
            <ListItemText primary='My Points' />
          </ListItem>
          <ListItem
            button
            onClick={()=>{
              history.push('/main/members');
              onCloseSm();
            }}
            selected={checkActive('/main/members')}
          >
            <ListItemIcon><PeopleOutlineIcon /></ListItemIcon>
            <ListItemText primary='Members' />
          </ListItem>
        </div>
      }
        <Divider />
        <ListItem button onClick={handleLogout}>
          <ListItemIcon><ExitToAppOutlinedIcon /></ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItem>
    </List>
    </>
  );
}
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft({childComp}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openSm, setOpenSm] = React.useState(false);
  const handleDrawerOpen = () => { setOpen(true); };
  const handleDrawerClose = () => { setOpen(false); };
  const matches = useMediaQuery('(max-width:500px)');
  React.useEffect(()=>{
    if(matches) setOpen(false);
  },[matches])
  return (
    <div style={{display: matches ? 'block' : 'flex'}}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {[classes.appBarShift]: open})}
      >
        <Toolbar>
          {matches ? 
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={()=>setOpenSm(true)}
              edge="start"
              className={clsx(classes.menuButton)}
            ><MenuIcon />
            </IconButton>
          :
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            ><MenuIcon />
            </IconButton>
          }
            <IconButton
              onClick={handleDrawerClose} 
              className={clsx(!open && classes.hide)}
            ><ChevronLeftIcon style={{color:'#fff'}}/>
            </IconButton>
          <Typography variant="h6" noWrap>
            NROI MALAYSIA POINTS RECORD SYSTEM
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        onClose={()=>matches ? setOpenSm(false) : ''}
        variant={matches ? '' : 'persistent'}
        anchor="left"
        open={matches? openSm : open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Divider />
        <DrawerContent onCloseSm={()=>matches ? setOpenSm(false) : ''}/>
      </Drawer>
      
      
      <div
        className={clsx( matches ? '' : classes.content,{[classes.contentShift]: open,},'drawerContent')}
      >
        <div className={classes.drawerHeader} />
        <Container component='main' maxWidth='lg' style={{marginTop:'2em', marginLeft: open && !matches ? 'inherit' : 'auto'}}>
          {childComp}
        </Container>
      </div>
    </div>
  );
}