import React from 'react';
import {DropzoneDialog} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

export default function UploadCert({btnTxt, setUploadedFile, openDzone, closeDzone}) {
    const [openDialog, setOpenDialog] = React.useState(false);
    React.useEffect(()=>{
        if(!btnTxt && openDzone !== openDialog){
            setOpenDialog(openDzone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[openDzone]);
    return (
        <div>
            {btnTxt ? 
                <div style={{textAlign:'right', marginTop:'.5em'}}>
                    <Button
                        onClick={()=>setOpenDialog(true)}
                        variant="outlined"
                        fullWidth
                        color="primary"
                        size='small'
                    ><CloudUploadOutlinedIcon/>&nbsp;{btnTxt || ''}
                    </Button>
                </div>
                :
                ''
            }
            <DropzoneDialog
                open={openDialog}
                clearOnUnmount={false}
                onClose={()=>{
                    setOpenDialog(false);
                    if(!btnTxt)closeDzone();
                }}
                onSave={(e)=>{
                    setUploadedFile(e);
                    setOpenDialog(false);
                    if(!btnTxt)closeDzone();
                }}
                onChange={(e)=>{
                    if(e.length >0){
                        setUploadedFile(e);
                        // setOpenDialog(false);
                        if(!btnTxt)closeDzone();
                    }
                }}
                cancelButtonText='Close'
                submitButtonText='Confirm'
                showPreviewsInDropzone={true}
                // acceptedFiles={['image/*','.pdf']}
                showPreviews={false}
                filesLimit={1}
                maxFileSize={5000000}
                dialogTitle={'Upload Certificate'}
            />
        </div>
    );
  }