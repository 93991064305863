import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AppNotiContext} from '../../../../AppContext.js';
import {requestPost} from '../../../utils/httpReq';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [userMail, setUserMail] = React.useState('');
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitPswd =()=>{
    if(userMail){
        console.log('PASSWORD SAMA');
        const bodyFormData = new FormData();
        bodyFormData.append('email', userMail);
        requestPost(
          `password/create`,
          bodyFormData,
          (resp)=> setAppNoti({...appNoti, openSuccess:true, sucessMsg: resp.status}),
          (loading)=>console.log(loading),
          (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
          'formData'
        );
    }else {
      console.log('COMPLETE -ALL-1ST!')
    }
  }

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <small>Reset Password</small>
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset your account password, please key in your registered email.
          </DialogContentText>
          <TextField
            autoFocus
            value={userMail}
            onChange={(e)=>setUserMail(e.target.value)}
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>handleSubmitPswd()} color="primary">
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
