import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {requestPost, requestGet} from '../../../../../utils/httpReq';
import {AppNotiContext} from '../../../../../../AppContext.js';
import {MainContext} from '../../../../src/Context';
import { RenderInputSlct } from '../../../../../Components/RenderInput';
import ImageDropZone from '../../../../../Components/ImageDropZone';
import {MyPointsContext} from '../src/Context';
import RenderMatchDetails from './RenderMatchDetails';

const roles=[
  {lbl:'RM', val:'RM'},
  {lbl:'SO', val:'SO'},
  {lbl:'CRO', val:'CRO'},
  {lbl:'RO', val:'RO'},
  // {lbl:'QM', val:'QM'},
]

export default function FormDialog({isEdit, openPointForm, closePointForm, pointDetails}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [myPointsData, setMyPointsData] = React.useContext(MyPointsContext);
  const [mainContextData, setMainContextData] = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [formInput, setFormInput] = React.useState({
    roles:'',
    match_id: '',
  });
  const handleClickOpen = () => {
    requestGet(
      `point/add/${JSON.parse(sessionStorage.getItem('userSession')).id}`,
      (resp)=>setMyPointsData({
        ...myPointsData,
        matchList: resp
      }),
      (loading)=>setMainContextData({...mainContextData, loading: loading}),
    );
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFormInput({
      roles: '',
      file: '',
    })
    if(isEdit) closePointForm();
  };
  const convertFormData = (objct) => {
    const formData = new FormData();
    Object.keys(objct).forEach(key => formData.append(key, objct[key]));
    return formData;
  }
  const HandleStorePoint=()=>{
    if(formInput.roles){
      if(isEdit){
        let outdata = pointDetails;
        outdata = {...outdata, roles: formInput.roles, file: formInput.file}
        requestPost(
          `point/update/${pointDetails.id}`,
          convertFormData(outdata),
          (resp)=> {
            setMyPointsData({...myPointsData, displayPoints:{...myPointsData.displayPoints, ...resp}});
            handleClose();
            setAppNoti({...appNoti, openSuccess:true, sucessMsg: 'Points Created Successfully!'});
          },
          (loading)=> setMainContextData({...mainContextData, loading: loading}),
          (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
        )
      } else {
        requestPost(
          `point/store/${JSON.parse(sessionStorage.getItem('userSession')).id}`,
          convertFormData(formInput),
          (resp)=> {
            setMyPointsData({...myPointsData, displayPoints:resp});
            handleClose();
            setAppNoti({...appNoti, openSuccess:true, sucessMsg: 'Points Updated Successfully!'});
          },
          (loading)=> setMainContextData({...mainContextData, loading: loading}),
          (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
        )
      }
    } else{
      alert ('Please Complete all fields to proceed');
    }
  }
  React.useEffect(()=>{
    if(openPointForm) {
      setOpen(true);
      if(isEdit) {
        setFormInput({
          roles: pointDetails.roles,
          file: pointDetails.file,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openPointForm]);
  return (
    <>
      {!isEdit &&
        <div style={{textAlign:'right'}}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add Points
          </Button>
        </div>
       }
      <Dialog open={open} onClose={()=>handleClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{isEdit ? 'Edit' : 'Add'} Points</DialogTitle>
        <DialogContent>
          <div className='form-cont'>
            <h4>Points Details</h4>
            {isEdit ?
                <RenderMatchDetails details={pointDetails}/>
              :
              <>
                <div>
                  <FormControl fullWidth margin='dense' variant="outlined" >
                    <InputLabel id='match_name'>Select Match</InputLabel>
                    <Select
                      labelId='match_name'
                      value={formInput.match_id || ''}
                      onChange={(e)=>setFormInput({...formInput, match_id: e.target.value})}
                      label='Select Match'
                    >
                      <MenuItem value="" disabled> <em>Select One</em></MenuItem>
                      {myPointsData.matchList.map((item, indx)=> <MenuItem key={indx} value={item.id} disabled={item.selected === '0' && item.status === '1'}>{item.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </div>
                {formInput.match_id && <RenderMatchDetails details={myPointsData.matchList.find(({ id })=> id === formInput.match_id)}/>}
              </>
            }
              <div>
                <RenderInputSlct
                  slctLbl= 'Role'
                  slctName= 'roles'
                  slctVal= {formInput.roles}
                  slctList={roles}
                  slctListLbl={{val:'val',lbl:'lbl'}}
                  slctOnChange={(val)=>setFormInput({...formInput, roles: val})}
                />
              </div>
              <div>
              <ImageDropZone
                setUploadedFile={(file)=>setFormInput({...formInput, file: file[0]})}
                btnTxt='Upload Points Card'
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{
            // handleClose();
            HandleStorePoint();
          }} color="primary">
            Request
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
