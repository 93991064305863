import React from 'react';
import { withRouter } from "react-router-dom";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MainContext } from '../../../../src/Context';
import {AppNotiContext} from '../../../../../../AppContext.js';
import { requestPost } from '../../../../../utils/httpReq';

function ProfilePage({uid,isDisabled,closeDialog}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [mainContextData, setMainContextData] = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [scsMsg, setscsMsg] = React.useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = ()=>{
    requestPost(
        `user/reject/${uid}`,
        '',
        (resp)=>{
            setscsMsg('Account Deleted Successfully!');
        },
        (loading)=> setMainContextData({ ...mainContextData, loading: loading }),
        (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
        'formData'
    )
  }
  return (
    <>
        <div>
            <Button
                disabled={isDisabled}
                color='secondary'
                onClick={()=>handleClickOpen()}
                style={{marginRight:'1em'}}
            > <DeleteOutlineIcon/>&nbsp;Delete Account 
            </Button>
        </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{backgroundColor:!!scsMsg ? '#289060' : '#f50057'}}>
                {!!scsMsg ? 'Success' : 'This action cannot be undone!'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!!scsMsg ?
                        scsMsg
                        :
                        <>Do take note that this action will <b>Permanently Delete all the data</b> that related to the selected account. <b>Confirm To Delete Account?</b></>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                    {!!scsMsg ?
                        <Button onClick={()=>window.location.reload()} color="secondary">
                            Close
                        </Button>
                        :
                        <>
                            <Button onClick={()=>handleDelete()} color="primary">
                                Proceed
                            </Button>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                        </>
                    }
            </DialogActions>
        </Dialog>
    </>
  );
}

export default  withRouter(ProfilePage);