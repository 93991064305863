import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {AdminVerifyProvider} from './src/Context';
import {
  Container,
  Paper,
} from '@material-ui/core';

import VerifyUser from './Components/VerifyUser';
import VerifyPoints from './Components/VerifyPoints';
import VerifyRank from './Components/VerifyRank';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <AdminVerifyProvider>
      {/* <Container component='main' maxWidth='lg'> */}
        <Paper elevation={3} style={{padding:'1em 1em 2em'}}>
          <div className={classes.root}>
            <h1 style={{fontWeight:400, fontSize:'1.4em'}}>Verification Request</h1>
            <AppBar position="static" color="default">
            <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
            >
            <Tab label="Member Request" {...a11yProps(0)} />
            <Tab label="Points Request" {...a11yProps(1)} />
            <Tab label="Rank Request" {...a11yProps(2)} />
            </Tabs>
            </AppBar>
            <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <VerifyUser/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <VerifyPoints/>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <VerifyRank/>
            </TabPanel>
            </SwipeableViews>
          </div>
        </Paper>
      {/* </Container> */}
    </AdminVerifyProvider>
  );
}
