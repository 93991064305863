import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AdminView from './AdminView';
const ITEM_HEIGHT = 48;
export default function LongMenu({uid}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewDetails, setViewDetails] = React.useState({ open:false, type: ''})
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, type) => {
    setAnchorEl(null);
    setViewDetails({
      open:true,
      type: type,
    });
  };
  return (
    <div>
        <AdminView
          uid={uid}
          openType={viewDetails.type}
          openDetails={viewDetails.open}
          closeDetails={()=>setViewDetails({ open:false, type: ''})}
        />
        <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
        > <ExpandMoreIcon />
        </IconButton>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
            style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
            },
            }}
        >
            <MenuItem onClick={(e)=>{handleClose(e,'profile');}}><AccountCircleOutlinedIcon/>&nbsp;&nbsp;Profile</MenuItem>
            <MenuItem onClick={(e)=>{handleClose(e, 'points');}}><GpsFixedIcon/>&nbsp;&nbsp;Points</MenuItem>
        </Menu>
    </div>
  );
}