import React from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@material-ui/core';
// import { format } from 'date-fns';
import './src/css/idCard.scss';
import profilePict from '../../src/img/profilePict.png'
import nroilogo from '../../../../../../src/img/nroilogo.png'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainDomain } from '../../../../../../utils/httpReq';

class ComponentToPrint extends React.Component {
  render() {
    const props = this.props.cardData;
    const dExpiry = JSON.parse(sessionStorage.getItem('userSession')).d_expiry;
    return (
      <div id='printItem' className='card-cont'>
        <div className='front-card'>
          <div className='bg' />
          <div className='card-content'>
            <div style={{padding:'2em 0 0'}}>
              <img
                src={`${mainDomain}${props.images}`}
                alt='profilePict'
                onError={(e)=>{
                  e.target.onerror = null;
                  e.target.src = profilePict
                }}
              />
              <p>
                NROI ID {props.iroa_id}<br/>
                  <small>
                    {props.d_join ? 
                        <>Member Since {props.d_join}<br/></>
                      :
                      ''
                    }
                    {dExpiry ? <>exp. Date {dExpiry}</> : ''}
                  </small>
              </p>
            </div>
            <div>
                <h3 style={{margin:'1.5em 0 0'}}>National Range Officer</h3>
                <p style={{margin:'0', lineHeight:1}}>
                  <small>
                    Official Identification of National Range Officer Institute Malaysia (NROI Malaysia)
                  </small>
                </p>
                <p style={{marginTop:'2.5em', textTransform:'uppercase'}}>{props.name}<br/><small>Malaysia</small></p>
                <div style={{textAlign:'right'}}><img src={nroilogo} alt='nroilogo'/></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function GenerateIDcard({cardData}) {
  // console.log('getYear==>', format(new Date(cardData.verified_at),'P'));
  console.log('getYear==>', cardData);
  const componentRef = React.useRef();
  const [generateCard, setGenerateCard] = React.useState(false);
    return (
      <div style={{display:'inline-block'}}>
        <Button
          variant='contained'
          style={{margin:'0 1em'}}
          onClick={()=>setGenerateCard(true)}
        > Generate ID Card
        </Button>
        <Dialog 
          disableBackdropClick
          disableEscapeKeyDown
          open={generateCard}
          onClose={()=>setGenerateCard()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Generate ID Card</DialogTitle>
          <DialogContent>
              <ComponentToPrint cardData={cardData} ref={componentRef} />
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => <Button
                variant='contained'
                color='primary'
              >Print Card</Button>}
              content={() => componentRef.current}
            />
            <Button
              variant='contained' 
                color='secondary'
                onClick={()=>setGenerateCard(false)}
            >Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}