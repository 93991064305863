import React from 'react';
import Button from '@material-ui/core/Button';
import ImageDropZone from '../../../../../Components/ImageDropZone';
import ImageCrop from '../../../../../Components/ImageCrop';
import profilePicto from '../src/img/profilePict.png';
import { mainDomain } from '../../../../../utils/httpReq';

export default function RenderUploadProfilePic({picVal, setPicVal, disabled}){
    const [dzoneOpen, setDzoneOpen] = React.useState(false);
    const [profilePict, setProfilePict] = React.useState('');
    const [cropImg, setCropImg] = React.useState(false);
    React.useEffect(()=>{
      if(profilePict){
        setCropImg(true);
      }
    },[profilePict]);
    return(
      <div style={{textAlign:'center', paddingTop:'1em'}}>
        <Button style={{display:'block', margin: 'auto',}} disabled={disabled} onClick={()=>setDzoneOpen(true)}>
            <img
              src={profilePict ? URL.createObjectURL(profilePict) : `${mainDomain}${picVal}`}
              alt='profilePict'
              onError={(e)=>{
                e.target.onerror = null;
                e.target.src = profilePicto
              }}
              style={{
                width: '10em',
                height: '10em',
                borderRadius: '50%',
              }}
            />
            {disabled ? '' :  <p style={{display:'block', margin: 0, color:'#979897'}}> Click To Upload Image</p>}
        </Button>
          <ImageDropZone
            setUploadedFile={(file)=>
              setProfilePict(file[0])
            }
            openDzone={dzoneOpen}
            closeDzone={()=>setDzoneOpen(false)}
          />
          <ImageCrop
            openImgCrop={cropImg}
            closeImgCrop={()=>setCropImg(false)}
            imageVal={profilePict ? URL.createObjectURL(profilePict) : ''}
            // imageVal={profilePict ? URL.createObjectURL(profilePict) : ''}
            setCroppedImage={(img)=>{
              // const pictFile = new File([img], "userProfile.jpg");
              // console.log('pictFile==>', pictFile, img);
              setPicVal(img);
            }}
          />
      </div>
    );
  }
  