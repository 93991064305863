import React from 'react';

export const MainContext = React.createContext();

export const MainContextProvider = props =>{
    const [mainContextData, setMainContextData] = React.useState({
        loading:false,
    });
    return(
        <MainContext.Provider value={[mainContextData, setMainContextData]}>
            {props.children}
        </MainContext.Provider>
    );
};