import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AddPointForm from './AddPointForm';
import {MyPointsContext} from '../src/Context';
import {AppNotiContext} from '../../../../../../AppContext.js';
import {MainContext} from '../../../../src/Context';
import {requestPost} from '../../../../../utils/httpReq';

export default function MoreOption({pointDetails, isDisabled}) {
  const [appNoti, setAppNoti] = React.useContext(AppNotiContext);
  const [myPointsData, setMyPointsData] = React.useContext(MyPointsContext);
  const [mainContextData, setMainContextData] = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditPoint, setIsEditPoint] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete=()=>{
      requestPost(
        `point/delete/${pointDetails.id}`,
        '',
        (resp)=> {
          setMyPointsData({...myPointsData, displayPoints:resp});
          setAppNoti({...appNoti, openSuccess:true, sucessMsg: 'Points Deleted Successfully!'});
          handleClose(true);
        },
        (loading)=> setMainContextData({...mainContextData, loading: loading}),
        (err)=>setAppNoti({...appNoti, openErr:true, errObj: err}),
      )
  }
  return (
    <>
      <AddPointForm
        isEdit
        pointDetails={pointDetails}
        openPointForm={isEditPoint}
        closePointForm={()=>setIsEditPoint(false)}
      />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          style={{color:'#fd1602'}}
          onClick={()=>{
            handleClose();
            setIsEditPoint(true);
          }}
          ><CreateOutlinedIcon/>Edit
        </MenuItem>
        <MenuItem
          style={{color:'#3f51b5'}}
          onClick={()=>handleDelete()}
        ><DeleteOutlineOutlinedIcon/>Delete
        </MenuItem>
      </Menu>
    </>
  );
}
