import React from 'react';
import TableData from '../../../../../../Components/TableData';
import { requestGet } from '../../../../../../utils/httpReq';
import { AdminVerifyContext } from '../../src/Context';
import RenderRankMenu from './RenderRankMenu';

export default function VerifyMemberRank() {
    const [adminVerifyData, setAdminVerifyData] = React.useContext(AdminVerifyContext);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(()=>{
        requestGet(
            `user/verify_rank_listing`,
            (resp)=>setAdminVerifyData({
                ...adminVerifyData,
                ranksReqData: resp
            }),
            (loading)=>setIsLoading(loading)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const renderRankTxt =(oldRank, newRank)=>{
        if(oldRank === newRank) return <p style={{textAlign:'center'}}>-</p>;
        return <p>{oldRank || <small>N/A</small>} <small>To</small> <b style={{color:'red'}}>{newRank}</b></p>
    }

  return (
      <TableData
        title="Rank Request Listing"
        isLoading={isLoading}
        data={adminVerifyData.ranksReqData || []}
        options={{ 
          pageSize: 10,
        }}
        columns={[
            {
                title: 'IROA/NROI ID',
                field: 'iroa_id'
            },
            {
                title: 'Name',
                field: 'name'
            },
            {
                title: 'Contact Number',
                field: 'contact_num'
            },
            {
                title: 'IROA Rank Request',
                render: rowData=> renderRankTxt(rowData.rank_stat,rowData.new_rank_stat)
            },
            {
                title: 'NROI Rank Request',
                render: rowData=> renderRankTxt(rowData.nat_rank_stat,rowData.new_nat_rank_stat)
            },
            {
                title: 'Action',
                sorting: false,
                render: rowData=> <RenderRankMenu userID={rowData.id} />
            }
          ]}
      />
  );
}
